import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import useStyles from './header-jss';

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

// eslint-disable-next-line
class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: [],
      openMenu: [],
      anchorEl: null,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpenMenu = this.handleOpenMenu.bind(this);
  }

  componentDidMount() {
    const { open } = this.props;
    setTimeout(() => {
      this.setState({ active: open });
    }, 50);
  }

  handleOpenMenu = (event, key, keyParent) => {
    const { openSubMenu } = this.props;
    openSubMenu(key, keyParent);
    this.setState({ anchorEl: event.currentTarget });
    setTimeout(() => {
      this.setState({
        openMenu: [key], // eslint-disable-line
      });
    }, 50);
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ openMenu: [] });
  };

  handleActiveParent = (key) => {
    this.setState({
      active: [key],
      openMenu: [],
    });
  };

  render() {
    const { classes, cx } = useStyles();

    const { open, dataMenu } = this.props;
    const { active, openMenu, anchorEl } = this.state;
    const getMenus = (parent, menuArray) =>
      menuArray.map((item, index) => {
        if (item.multilevel) {
          return false;
        }
        if (item.child || item.linkParent) {
          return (
            <div key={index.toString()}>
              <Button
                aria-owns={open ? 'menu-list-grow' : undefined}
                component={LinkBtn}
                to={item.linkParent ? item.linkParent : '#'}
                buttonRef={(node) => {
                  this.anchorEl = node;
                }}
                className={cx(
                  classes.headMenu,
                  open.indexOf(item.key) > -1 ? classes.opened : '',
                  active.indexOf(item.key) > -1 ? classes.selected : ''
                )}
                onClick={(event) =>
                  this.handleOpenMenu(event, item.key, item.keyParent)
                }
              >
                {item.name}
                {!item.linkParent ? (
                  <ExpandMore className={classes.rightIcon} />
                ) : (
                  <span className={classes.rightIcon}>&nbsp;&nbsp;</span>
                )}
              </Button>
              {!item.linkParent && (
                <Popper
                  open={openMenu.indexOf(item.key) > -1}
                  anchorEl={anchorEl}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <Paper className={classes.dropDownMenu}>
                        <ClickAwayListener onClickAway={this.handleClose}>
                          <List
                            role="menu"
                            component="nav"
                            className={classes.paperMenu}
                          >
                            {getMenus(item.key, item.child)}
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              )}
            </div>
          );
        }
        if (item.title) {
          return (
            <ListSubheader
              component="div"
              key={index.toString()}
              className={classes.title}
            >
              {item.name}
            </ListSubheader>
          );
        }
        return (
          <ListItem
            key={index.toString()}
            button
            exact
            className={classes.menuItem}
            activeClassName={classes.active}
            component={LinkBtn}
            to={item.link}
            onClick={() => this.handleActiveParent(parent)}
          >
            <ListItemText primary={item.name} />
          </ListItem>
        );
      });
    return (
      <nav className={classes.mainMenu}>
        <div>{getMenus(null, dataMenu)}</div>
      </nav>
    );
  }
}

MainMenu.propTypes = {
  open: PropTypes.object.isRequired,
  openSubMenu: PropTypes.func.isRequired,
  dataMenu: PropTypes.array.isRequired,
};

const openAction = (key, keyParent) => ({
  type: 'OPEN_SUBMENU',
  key,
  keyParent,
});
const reducer = 'ui';

const mapStateToProps = (state) => ({
  force: state, // force active class for sidebar menu
  open: state.getIn([reducer, 'subMenuOpen']),
});

const mapDispatchToProps = (dispatch) => ({
  openSubMenu: bindActionCreators(openAction, dispatch),
});

const MainMenuMapped = connect(mapStateToProps, mapDispatchToProps)(MainMenu);

export default MainMenuMapped;
