import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { useAuth0 } from 'config/react-auth0-spa';
import ChangeOrganizationDialog from 'containers/UiElements/User/ChangeOrganizationDialog';
import axios from 'axios';

const UserMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [organizationsArray, setOrganizationsArray] = useState([]);

  const {
    isAuthenticated,
    logout,
    user,
    getTokenSilently,
    organizationInfo,
    accessTokenClaims,
  } = useAuth0();
  const currentOrganization = organizationInfo.organizationId;
  const { roleId } = accessTokenClaims;

  const fetchOrganizations = async () => {
    try {
      const token = await getTokenSilently();
      const response = await axios.get(
        `${process.env.PLATFORM_BACKEND_API_URL}api/user/organizations`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setOrganizationsArray(response.data);
    } catch (error) {
      console.error('Failed to fetch organizations:', error);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenMenu(null);
  };

  const handleMenu = (menu) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(openMenu === menu ? null : menu);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  useEffect(() => {
    if (openDialog) {
      fetchOrganizations();
    }
  }, [openDialog]);

  useEffect(() => {
    if (roleId !== '1') {
      fetchOrganizations();
    }
  }, []);

  const AuthLogout = () => {
    const shouldLogout = () => {
      if (isAuthenticated) {
        logout({ returnTo: process.env.PLATFORM_FRONTEND_URL });
      }
    };

    return (
      <Button
        size="small"
        color="secondary"
        onClick={shouldLogout}
        style={{ padding: 0 }}
      >
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <Typography variant="button" style={{ margin: 0 }}>
          Log Out
        </Typography>
      </Button>
    );
  };

  return (
    <div>
      <Button onClick={handleMenu('user-setting')}>
        <Avatar src={user ? user.picture : avatarApi[11]} />
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openMenu === 'user-setting'}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to="/user-profile">
          My Profile
        </MenuItem>
        <Divider />
        {roleId !== '1' &&
          React.Children.toArray([
            <MenuItem onClick={handleOpenDialog}>Change organization</MenuItem>,
            <Divider />,
            <ChangeOrganizationDialog
              open={openDialog}
              onClose={handleCloseDialog}
              user={user}
              organizations={organizationsArray}
              currentOrganization={currentOrganization}
            />,
          ])}
        <MenuItem>
          <AuthLogout />
        </MenuItem>
      </Menu>
    </div>
  );
};

UserMenu.propTypes = {
  dark: PropTypes.bool,
};

UserMenu.defaultProps = {
  dark: false,
};

export default UserMenu;
