import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import brand from '../../dan-dummy/dummy/brand';
import logo from 'dan-images/logo.svg';
import useStyles from '../Sidebar/sidebar-jss';

function createData(id, name, url) {
  return {
    id,
    name,
    url,
  };
}

const MenuContent = (props) => {
  const { menuList, gotoSlide, active } = props;
  const { classes, cx } = useStyles();
  const getMenus = (menuArray) =>
    menuArray.map((item, index) => (
      <ListItem
        key={index.toString()}
        button
        className={cx(
          classes.headCapital,
          active === item.id && classes.active
        )}
        to={item.url}
        onClick={() => gotoSlide(item.id)}
      >
        <ListItemText
          classes={{ primary: classes.primary }}
          variant="inset"
          primary={item.name}
        />
      </ListItem>
    ));
  return (
    <div className={classes.drawerInner}>
      <div className={classes.drawerHeader}>
        <div className={classes.brandBig}>
          <img src={logo} alt={brand.name} />
          <h3>{brand.name}</h3>
        </div>
      </div>
      <div
        className={cx(
          classes.menuContainer,
          classes.landingNav,
          classes.rounded
        )}
      >
        <List className={classes.dense} component="nav">
          {getMenus(menuList)}
        </List>
        <Typography variant="caption" className={classes.copyright}>
          &copy; 2020 Dandelion Designs
          <br />
          All Right Reserved
        </Typography>
      </div>
    </div>
  );
};

MenuContent.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.number.isRequired,
  menuList: PropTypes.array.isRequired,
  gotoSlide: PropTypes.func.isRequired,
};

MenuContent.defaultProps = {
  toggleDrawerOpen: () => {},
  loadTransition: () => {},
};

const MenuContentStyle = MenuContent;

class SideNav extends React.Component {
  state = {
    anchor: 'left',
    menuList: [
      createData(0, 'Home', '#banner'),
      createData(1, 'Feature', '#feature'),
      createData(2, 'Showcase', '#showcase'),
      createData(3, 'Testimonials', '#testimonials'),
      createData(4, 'Technology', '#tech'),
      createData(5, 'Pricing', '#pricing'),
      createData(6, 'Contact', '#contact'),
    ],
  };

  render() {
    const { anchor, menuList } = this.state;
    const { open, gotoSlide, curSlide } = this.props;
    const { classes, cx } = useStyles();
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: cx(
            classes.drawer,
            classes.drawerPaper,
            !open ? classes.drawerPaperClose : ''
          ),
        }}
        open={open}
        anchor={anchor}
      >
        <MenuContentStyle
          drawerPaper={open}
          menuList={menuList}
          gotoSlide={gotoSlide}
          active={curSlide}
        />
      </Drawer>
    );
  }
}

SideNav.propTypes = {
  open: PropTypes.bool.isRequired,
  gotoSlide: PropTypes.func.isRequired,
  curSlide: PropTypes.number.isRequired,
};

export default SideNav;
