import React from 'react';
import { PropTypes } from 'prop-types';
import { Router, Switch, Route } from 'react-router-dom';
import NotFound from 'containers/Pages/Standalone/NotFoundDedicated';
import Application from './Application';
import ThemeWrapper from './ThemeWrapper';
import PrivateRoute from './PrivateRoute';
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const App = (props) => {
  return (
    <ThemeWrapper>
      <Router history={props.history}>
        <>
          <Switch>
            <PrivateRoute
              path="/"
              render={(props) => <Application {...props} />}
            />
            <Route component={NotFound} />
          </Switch>
        </>
      </Router>
    </ThemeWrapper>
  );
};

App.propTypes = {
  history: PropTypes.object.isRequired,
};

export default App;
