import React, { useState, useEffect, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { tss } from 'tss-react/mui';

const useStyles = tss.create((theme, classes) => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  input: {
    width: '100%',
    padding: '0 8px 0',
    '& input:focus': {
      outline: '1px solid green',
      boxShadow: '0 0 2pt 2pt rgba(0, 128, 0, 0.2)',
    },
  },
  message: {
    color: 'green',
    margin: 0,
    fontSize: '0.75rem',
    marginTop: '3px',
    fontWeight: '400',
    lineHeight: '1.66',
    position: 'absolute',
    bottom: '-20px',
    zIndex: 10000,
    padding: '0 8px 0',
  },
  statusError: {
    color: 'red',
  },
  divider: {
    height: 45,
    margin: 4,
    color: '#303030',
    marginLeft: '12px',
    marginRight: '12px',
  },
  button: {
    borderRadius: 0,
    minWidth: '15px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
}));

const buttonStyles = {
  tooltip: {
    fontSize: '25px',
    color: 'white',
    float: 'right',
    cursor: 'pointer',
  },
};

const CopyValue = (props) => {
  const {
    value,
    tooltipValue,
    copyButton,
    copyButtonOnClick,
    renewButton,
    renewButtonOnClick,
    copyMessage,
    copyStatus,
    styling,
    renewButtonRef,
  } = props;
  const inputRef = useRef(null);
  const { classes, cx } = useStyles();
  const [localMessage, setLocalMessage] = useState(copyMessage);

  useEffect(() => {
    setLocalMessage(copyMessage);
  }, [copyMessage]);

  const parameterTest = (bool) => bool !== undefined && true;

  return (
    <>
      <Paper component="form" className={classes.root} style={styling}>
        <Tooltip title={tooltipValue}>
          <InputBase
            className={classes.input}
            value={value}
            inputRef={inputRef}
            onBlur={() => setLocalMessage('')}
          />
        </Tooltip>

        {renewButton && (
          <>
            <IconButton
              style={{ display: parameterTest(renewButtonRef) && 'none' }}
              className={classes.button}
              ref={renewButtonRef}
              onClick={() => {
                renewButtonOnClick();
                setLocalMessage(copyMessage);
              }}
            >
              <Tooltip title={renewButton.tooltip}>
                <i
                  role="tooltip"
                  className={renewButton.icon}
                  style={buttonStyles.tooltip}
                />
              </Tooltip>
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
          </>
        )}

        {copyButton && (
          <IconButton
            className={classes.button}
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(`${value}`);
                if (inputRef.current) {
                  inputRef.current.focus();
                  setLocalMessage(copyMessage);
                }
                copyButtonOnClick();
              } catch (err) {}
            }}
          >
            <Tooltip title={copyButton.tooltip}>
              <i
                role="tooltip"
                className="ion-ios-copy"
                style={buttonStyles.tooltip}
              />
            </Tooltip>
          </IconButton>
        )}
        <Typography
          component={'p'}
          className={`${classes.message} ${
            copyStatus === 'error' && classes.statusError
          }`}
        >
          {localMessage}
        </Typography>
      </Paper>
    </>
  );
};
CopyValue.defaultProps = {
  copyButton: { icon: 'ion-ios-copy', tooltip: 'Copy' },
};
CopyValue.propTypes = {
  renewButton: PropTypes.object,
  copyButton: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};
export default CopyValue;
