import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  toggleAction,
  openAction,
  playTransitionAction,
} from 'dan-redux/actions/uiActions';
import axios from 'axios';
import LeftSidebarLayout from './layouts/LeftSidebarLayout';
import useStyles from './appStyles-jss';
import { useAuth0 } from '../../config/react-auth0-spa';
import Snackbar from '../UiElements/Snackbar';
import { get } from 'lodash';

const Dashboard = (props) => {
  const { classes, cx } = useStyles();

  const [appHeight, setAppHeight] = useState(0);

  const [campaignObject, setCampaignObject] = useState({});
  const {
    children,
    toggleDrawer,
    sidebarOpen,
    loadTransition,
    pageLoaded,
    mode,
    history,
    gradient,
    deco,
    bgPosition,
    layout,
  } = props;

  const titleException = [
    '/app',
    '/app/crm-dashboard',
    '/app/crypto-dashboard',
  ];
  const parts = history.location.pathname.split('/');
  const lastPart = parts[parts.length - 1];
  const secondLastPart = parts[parts.length - 2];
  if (lastPart.length === 0 && secondLastPart.length !== 0) {
    parts.pop();
  }
  const campaignIdConst = parts[parts.length - 1];
  const parentRoute = parts[parts.length - 2].replace('-', ' ');
  const whereWeAreRoute = parts[parts.length - 1].replace('-', ' ');
  //const currentHomepage = 'Dashboard';
  const { loading, getTokenSilently, accessTokenClaims } = useAuth0();
  const { roleId } = accessTokenClaims;
  let currentHomepage = '';
  if (roleId === '1') {
    currentHomepage = 'Organizations';
  } else {
    currentHomepage = 'Dashboard';
  }

  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    variant: 'success',
    status: null,
  });

  const getCampaignIdRequest = async () => {
    try {
      const token = await getTokenSilently();

      const response = await axios.get(
        process.env.CAMPAIGNS_URL + '/' + campaignIdConst,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      await setCampaignObject(response.data);
    } catch (error) {
      const x = error;
      if (x.response.data.status === 400) {
        history.push(`/notfound`);
      }
      setOpenSnackbar({
        ...openSnackbar,
        open: true,
        message: 'Campaign not found',
        variant: 'error',
        status: error.status,
      });
    }
  };

  const placeParser = (homepageNameString) => {
    const whereWeAreRoute = parts[parts.length - 1].replace('-', ' ');
    const campaignId = parts[parts.length - 1];
    const parentRoute = parts[parts.length - 2].replace('-', ' ');

    if (
      (parentRoute === 'campaigns' || parentRoute === 'analytics') &&
      campaignObject
    ) {
      // getCampaignIdRequest();
      return campaignObject.name;
    }
    if (parts[1] === 'resources') {
      return 'Resources';
    }
    if (whereWeAreRoute.length <= 0) {
      return homepageNameString;
    }

    return whereWeAreRoute;

    // const place = " ";
  };

  useEffect(() => {
    const { history, initialOpen, loadTransition } = props;

    // Adjust min height
    setAppHeight(window.innerHeight + 112);

    // Set expanded sidebar menu
    const currentPath = history.location.pathname;
    initialOpen(currentPath);
    // Play page transition
    loadTransition(true);

    // Execute all arguments when page changes
    history.listen(() => {
      window.scrollTo(0, 0);
      setTimeout(() => {
        loadTransition(true);
      }, 500);
    });
  }, []);

  useEffect(() => {
    if (
      (parentRoute === 'campaigns' || parentRoute === 'analytics') &&
      whereWeAreRoute.length >= 20 &&
      !loading
    ) {
      getCampaignIdRequest();
    }
  }, [loading]);

  useEffect(() => {
    if (
      (parentRoute === 'campaigns' || parentRoute === 'analytics') &&
      parts[parts.length - 1].length >= 20 &&
      parts[parts.length - 1] !== campaignObject.campaignId
    ) {
      setCampaignObject({});
      getCampaignIdRequest();
    }
  }, [JSON.stringify(parts)]);

  const place = placeParser(currentHomepage);

  useEffect(() => {
    if (roleId === '1') {
      history.push('/organizations');
    }
  }, []);

  return (
    <div
      style={{ minHeight: appHeight }}
      className={cx(
        classes.appFrameInner,
        layout === 'top-navigation' || layout === 'mega-menu'
          ? classes.topNav
          : classes.sideNav,
        mode === 'dark' ? 'dark-mode' : 'light-mode'
      )}
    >
      {
        /* Left Sidebar Layout */
        layout === 'left-sidebar' && (
          <LeftSidebarLayout
            history={history}
            toggleDrawer={toggleDrawer}
            loadTransition={loadTransition}
            sidebarOpen={sidebarOpen}
            pageLoaded={pageLoaded}
            mode={mode}
            gradient={gradient}
            deco={deco}
            bgPosition={bgPosition}
            place={place}
            additionalData={campaignObject}
            titleException={titleException}
          >
            {children}
          </LeftSidebarLayout>
        )
      }
      <Snackbar
        open={openSnackbar.open}
        onClose={() =>
          setOpenSnackbar({
            ...openSnackbar,
            open: false,
          })
        }
        variant={openSnackbar.variant}
        message={openSnackbar.message}
        status={`${openSnackbar.status}`}
      />
    </div>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  initialOpen: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  gradient: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
  bgPosition: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  sidebarOpen: state.ui.sidebarOpen,
  pageLoaded: state.ui.pageLoaded,
  mode: state.ui.type,
  gradient: state.ui.gradient,
  deco: state.ui.decoration,
  layout: state.ui.layout,
  bgPosition: state.ui.bgPosition,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDrawer: () => dispatch(toggleAction),
  initialOpen: bindActionCreators(openAction, dispatch),
  loadTransition: bindActionCreators(playTransitionAction, dispatch),
});

const DashboardMaped = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardMaped;
