import React, { useState } from 'react';
import { useAuth0 } from 'config/react-auth0-spa';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { PapperBlock } from 'dan-components';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useForm, Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Snackbar from 'containers/UiElements/Snackbar/Snackbar';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(() => ({
  dialogContent: {
    padding: '0px',
  },
  textContent: {
    padding: '10px 10px 20px',
  },
  textInput: {
    padding: '8px',
  },
}));

const ChangeOrganizationDialog = (props) => {
  const { open, onClose, organizations, currentOrganization } = props;
  const { classes } = useStyles();
  const { control, handleSubmit } = useForm();
  const { isAuthenticated, loginWithRedirect, getNewTokenForOrganization } =
    useAuth0();
  const history = useHistory();
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
    variant: 'success',
    status: null,
  });

  const handleClose = async () => {
    onClose();
  };

  const switchOrganization = async (organizationData) => {
    if (!isAuthenticated) {
      await loginWithRedirect();
      console.log('User is not authenticated, redirecting to login');
      return;
    }
    history.push('/');
    try {
      await getNewTokenForOrganization(organizationData);
      setOpenSnackbar({
        open: true,
        message: `Organization changed to: ${organizationData.organizationName}`,
        variant: 'success',
      });
    } catch (error) {
      console.error('Error switching organization:', error);
      setOpenSnackbar({
        open: true,
        message: `Failed to change organization: ${error.message}`,
        variant: 'error',
      });
    }
  };

  const onSubmit = (data) => {
    const organizationData = organizations.find(
      (org) => org.organizationId === data.organization
    );
    console.log('Submitting with organization data:', organizationData);
    switchOrganization(organizationData);
    handleClose();
  };

  return (
    <Grid container justify="center">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogContent className={classes.dialogContent}>
          <PapperBlock
            title="Change organization"
            noMargin
            icon="ion-ios-gear-outline"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.textContent}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="organization-select-label">
                    Organization
                  </InputLabel>
                  <Controller
                    name="organization"
                    control={control}
                    defaultValue={currentOrganization}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="organization-select-label"
                        label="Organization"
                        onChange={(event) => {
                          // Only update the form control's value, not the selected organization yet
                          field.onChange(event.target.value);
                        }}
                      >
                        {organizations.map((organization, index) => (
                          <MenuItem
                            key={index}
                            value={organization.organizationId}
                          >
                            {organization.organizationName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
              <DialogActions>
                <Box margin={1}>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Choose organization
                  </Button>
                </Box>
              </DialogActions>
            </form>
          </PapperBlock>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={openSnackbar.open}
        onClose={() => setOpenSnackbar({ ...openSnackbar, open: false })}
        variant={openSnackbar.variant}
        message={openSnackbar.message}
        status={openSnackbar.status}
      />
    </Grid>
  );
};

export default ChangeOrganizationDialog;
