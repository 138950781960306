const GetErrorMessage = (status) => {
  if (status >= 200 && status < 300) {
    return `SUCCESS ${status}: Request successful.`;
  }
  if (status >= 400 && status < 500) {
    return `CLIENT ERROR ${status}: It looks like there is a problem with your request.`;
  }
  if (status >= 500 && status < 600) {
    return `SERVER ERROR ${status}: It looks like there is a problem on server side. `;
  }
  return `ERROR ${status}`;
};

export default GetErrorMessage;
