import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import FileIcon from '@mui/icons-material/Description';
import ActionDelete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloudUpload from '@mui/icons-material/CloudUpload';
import 'dan-styles/vendors/react-dropzone/react-dropzone.css';
import Typography from '@mui/material/Typography';
import isImage from './helpers/helpers.js';
import { tss } from 'tss-react/mui';

const useStyles = tss.create((theme, classes) => ({
  dropItem: {
    borderColor: theme.palette.divider,
    background: theme.palette.background.default,
    borderRadius: theme.rounded.medium,
    color: theme.palette.text.disabled,
    textAlign: 'center',
  },
  uploadIconSize: {
    display: 'inline-block',
    '& svg': {
      width: 72,
      height: 72,
      fill: theme.palette.secondary.main,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  button: {
    marginTop: 20,
  },
}));

class MaterialDropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSnackBar: false,
      errorMessage: '',
      files: this.props.files, // eslint-disable-line
      acceptedFiles: this.props.acceptedFiles, // eslint-disable-line
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(filesVal) {
    const { files } = this.state;
    const { filesLimit } = this.props;
    let oldFiles = files;
    const filesLimitVal = filesLimit || '3';
    oldFiles = oldFiles.concat(filesVal);
    if (oldFiles.length > filesLimit) {
      this.setState({
        openSnackBar: true,
        errorMessage: 'Cannot upload more than ' + filesLimitVal + ' items.',
      });
    } else {
      this.props.onChange(oldFiles);
      this.setState({ files: oldFiles });
    }
  }

  onDropRejected() {
    // TODO: Uncaught TypeError: Cannot read property 'setState' of undefined
    this.setState({
      openSnackBar: true,
      errorMessage: 'File too big, max size is 3MB',
    });
  }

  handleRequestCloseSnackBar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  handleRemove(file, fileIndex) {
    const thisFiles = this.state.files; // eslint-disable-line
    // This is to prevent memory leaks.
    window.URL.revokeObjectURL(file.preview);

    thisFiles.splice(fileIndex, 1);
    this.props.onChange(thisFiles);
    this.setState({ files: thisFiles });
  }

  render() {
    const { showPreviews, maxSize, text, showButton, filesLimit, ...rest } =
      this.props;

    const { acceptedFiles, files, openSnackBar, errorMessage } = this.state;

    const { classes, cx } = useStyles();

    const fileSizeLimit = maxSize || 3000000;
    const deleteBtn = (file, index) => (
      <div className="middle">
        <IconButton onClick={() => this.handleRemove(file, index)}>
          <ActionDelete className="removeBtn" />
        </IconButton>
      </div>
    );
    const previews = (filesArray) =>
      filesArray.map((file, index) => {
        const base64Img = URL.createObjectURL(file);
        if (isImage(file)) {
          return (
            <div key={index.toString()}>
              <div className="imageContainer col fileIconImg">
                <figure className="imgWrap">
                  <img
                    className="smallPreviewImg"
                    src={base64Img}
                    alt="preview"
                  />
                </figure>
                {deleteBtn(file, index)}
              </div>
            </div>
          );
        }
        return (
          <div key={index.toString()}>
            <div className="imageContainer col fileIconImg">
              <FileIcon className="smallPreviewImg" alt="preview" />
              {deleteBtn(file, index)}
            </div>
          </div>
        );
      });
    let dropzoneRef;
    return (
      <div>
        <Dropzone
          accept={acceptedFiles.join(',')}
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}
          acceptClassName="stripes"
          rejectClassName="rejectStripes"
          maxSize={fileSizeLimit}
          ref={(node) => {
            dropzoneRef = node;
          }}
          {...rest}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={cx(classes.dropItem, 'dropZone')}
            >
              <div className="dropzoneTextStyle">
                <input {...getInputProps()} />
                <Typography variant="body1" className="dropzoneParagraph">
                  {text}
                </Typography>
                <div className={classes.uploadIconSize}>
                  <CloudUpload />
                </div>
              </div>
            </div>
          )}
          {/* end */}
        </Dropzone>
        {showButton && (
          <Button
            className={classes.button}
            fullWidth
            variant="contained"
            onClick={() => {
              dropzoneRef.open();
            }}
            color="secondary"
          >
            Click to upload file(s)
            <span className={classes.rightIcon}>
              <CloudUpload />
            </span>
          </Button>
        )}
        <div className="row preview">{showPreviews && previews(files)}</div>
        <Snackbar
          open={openSnackBar}
          message={errorMessage}
          autoHideDuration={4000}
          onClose={this.handleRequestCloseSnackBar}
        />
      </div>
    );
  }
}

MaterialDropZone.propTypes = {
  files: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired,
  acceptedFiles: PropTypes.array,
  showPreviews: PropTypes.bool.isRequired,
  showButton: PropTypes.bool,
  maxSize: PropTypes.number.isRequired,
  filesLimit: PropTypes.number.isRequired,
};

MaterialDropZone.defaultProps = {
  acceptedFiles: [],
  showButton: false,
  onChange: () => {},
};

export default MaterialDropZone;
