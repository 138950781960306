import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { connect } from 'react-redux';
import appTheme from '../../styles/theme/applicationTheme';
import { tss } from 'tss-react/mui';

const useStyles = tss.create(({ theme }) => ({
  root: {
    width: '100%',
    minHeight: '100%',
    marginTop: 0,
    zIndex: 1,
  },
  loading: {
    zIndex: '10 !important',
    position: 'fixed !important',
    top: 0,
    left: 0,
    width: '100%',
    opacity: 1,
    transition: 'opacity .5s ease',
  },
  loadingWrap: {
    background: 'none !important',
  },
  bar: {
    background: 'rgba(255, 255, 255, 0.7) !important',
  },
  hide: {
    opacity: 0,
  },
}));

const isBrowser = typeof document !== 'undefined';
let insertionPoint;

if (isBrowser) {
  const emotionInsertionPoint = document.querySelector(
    'meta[name="emotion-insertion-point"]'
  );
  insertionPoint = emotionInsertionPoint ?? undefined;
}

export const ThemeContext = React.createContext(undefined);

function ThemeWrapper(props) {
  const { classes } = useStyles();
  const [progress, setProgress] = useState(0);
  const { children, color, mode, direction } = props;

  // default state
  const [theme, setTheme] = useState(appTheme(color, mode, direction));

  const handleChangeMode = (mode) => {
    // eslint-disable-line
  };

  const cacheRTL = createCache({
    key: 'mui-style-rtl',
    stylisPlugins: [prefixer, rtlPlugin],
    insertionPoint,
  });

  console.log('mode is' + mode);

  const muiTheme = createTheme(appTheme(color, mode, direction));

  return (
    <ThemeProvider theme={muiTheme}>
      <div className={classes.root}>{children}</div>
    </ThemeProvider>
  );
}

ThemeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  gradient: PropTypes.bool.isRequired,
  decoration: PropTypes.bool.isRequired,
  bgPosition: PropTypes.string.isRequired,
  palette: PropTypes.array.isRequired,
  layout: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  color: state.ui.theme,
  palette: state.ui.palette,
  mode: state.ui.type,
  gradient: state.ui.gradient,
  decoration: state.ui.decoration,
  bgPosition: state.ui.bgPosition,
  layout: state.ui.layout,
  direction: state.ui.direction,
});

const ThemeWrapperMapped = connect(mapStateToProps)(ThemeWrapper);

export default ThemeWrapperMapped;
